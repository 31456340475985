.hero-section {
  width: 80%;
  margin: 3rem;
}

.hero-container {
  padding: 8rem 0;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.hero-left {
  padding: 0;
}

.hero-image {
  width: 700px;
  height: 500px;
}

.hero-left h1 {
  font-size: 5rem;
  line-height: 1;
  color: black;
}

@media screen and (max-width: 768px) {
  .hero-image {
    width: 390px;
    height: 250px;
    padding: 0rem 1rem;
  }

  .hero-left {
    padding: 4rem 0;
  }

  .hero-left h1 {
    font-size: 2.8rem;
    text-align: center;
  }

  .hero-container {
    padding: 2.5rem 0rem;
    display: inline-block;
  }

  .hero-section {
    width: 100%;
    margin: 0rem;
  }
}