* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(241, 241, 241);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}