.technology {
  padding: 5rem 15rem;
}

.technology h1 {
  color: rgb(20, 72, 116);
  text-align: left;
  font-size: 40px;
  padding-bottom: 3rem;
}

.technology p {
  text-align: justify;
  font-size: 25px;
  color: black;
  line-height: 2.5rem;
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .technology {
    padding: 5rem 2rem;
  }

  .technology h1 {
    text-align: center;
    font-size: 38px;
  }

  .technology p {
    font-size: 18.5px;
    line-height: 2rem;
    padding-bottom: 5px;
  }
}