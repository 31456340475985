.about {
  padding: 5rem 15rem;
}

.about h1 {
  color: rgb(20, 72, 116);
  text-align: left;
  font-size: 40px;
  padding-bottom: 3rem;
}

.about-paragraphs p {
  text-align: justify;
  color: black;
  font-size: 25px;
  padding-bottom: 10px;
  line-height: 2.5rem;
}

@media screen and (max-width: 768px) {
  .about {
    padding: 5rem 2rem;
  }

  .about h1 {
    text-align: center;
    font-size: 38px;
  }

  .about-paragraphs p {
    font-size: 18.5px;
    line-height: 2rem;
    padding-bottom: 5px;
  }
}