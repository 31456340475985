.contact {
  padding: 3rem 6rem;
}

.contact h1 {
  color: rgb(20, 72, 116);
  text-align: center;
  font-size: 40px;
  padding-bottom: 3rem;
}

.contact-address h2 {
  color: rgb(20, 72, 116);
  text-align: left;
  font-size: 35px;
  padding-bottom: 1rem;
}

.contact-address p {
  color: black;
  text-align: left;
  font-size: 20px;
}

.contact-map iframe {
  padding-top: 2rem; 
  width: 600px;
  height: 450px; 
}

@media screen and (max-width: 768px) {
  .contact {
    padding: 5rem 2rem;
  }

  .contact h1 {
    text-align: center;
    font-size: 38px;
  }

  .contact-address h2 {
    font-size: 30px;
  }

  .contact-address p {
    font-size: 18px;
  }

  .contact-map iframe {
    padding-top: 4rem; 
    width: 320px;
    height: 300px;
  }
}