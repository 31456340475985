.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(20, 72, 116);
  color: #fff;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  height: 80px;
  position: relative;
}

.navbar-logo {
  font-size: 24px;
  font-weight: bold;
}

.navbar-icon {
  cursor: pointer;
  display: none;
  font-size: 24px;
}

.navbar-links {
  display: flex;
}

.navbar-links ul {
  color: #f3f3f3;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  /*margin: 0 10px;*/
  font-size: 20px;
  padding: 0 1rem;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 0.5rem 1.5rem;
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: calc(100%);
    left: 0;
    width: 100%;
    background-color: rgb(15, 54, 88);
    padding: 10px;
    z-index: 1;
  }

  .navbar-links.open {
    display: flex;
    flex-direction: column;
  }
  
  .navbar-icon {
    display: block;
  }
  
  .navbar-links ul {
    flex-direction: column;
  }

  .navbar-links li {
    margin: 5px 0;
  }
}